
import { Vue, Component } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { NavigationGuardNext, Route } from 'vue-router';
import { AxiosError } from 'axios';
import InputJpDate from '@/components/Parts/InputJpDate.vue';
import VacationInfo from '@/model/posts/vacation/VacationInfo';
import VacationRequestApi from '@/module/api/VacationRequestApi';
import PostId from '@/model/posts/PostId';
import BackButton from '@/components/Parts/BackButton.vue';
import PostConfirmedInfo from '@/model/posts/PostConfirmedInfo';
import UserBaseInfoClass from '@/model/user/UserBaseInfo';
import CommentPostView from '@/components/post/CommentPostView.vue';
import CommentListView from '@/components/post/CommentListView.vue';
import CommentDetail from '@/model/posts/comment/CommentDetail';
import CommentId from '@/model/posts/comment/CommentId';

Component.registerHooks([
  'beforeRouteEnter'
]);
@Component({
  components: {
    InputJpDate,
    CommentListView,
    CommentPostView,
    BackButton
  }
})
export default class VacationRequest extends Vue {
  @State('loginUser') loginUser!: UserBaseInfoClass;
  @Getter('isAdminGroup') isAdminGroup!:boolean;
  vacationInfo:VacationInfo = new VacationInfo();
  errorMessage:string = '';
  confirmedUserList: PostConfirmedInfo[] = [];

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<VacationRequest>): void {
    next((vm: VacationRequest) => vm.initialize(to));
  }
  initialize(to: Route): void {
    const postId = new PostId(Number(to.params.postId as string));

    // 休暇連絡情報取得 & 既読
    VacationRequestApi.getViewer(postId).then((res) => {
      this.vacationInfo = VacationInfo.clone(res.data);
    }).catch((err: AxiosError) => {
      if (err.response?.status === 403) {
        this.errorMessage = err.response.data.detail;
        return;
      }
      this.$root.$emit('event-show-snackbar-infinity', '予期しないエラーが発生しました。');
    });
    // 確認者取得
    VacationRequestApi.findConfirmedUser(String(postId.value)).then((res) => {
      this.confirmedUserList = res.data;
    });
  }

  hasError(): boolean {
    return this.errorMessage.length > 0;
  }
  hasData(): boolean {
    return !this.vacationInfo.postId.isEmpty();
  }

  // 確認処理
  confirmed():void{
    VacationRequestApi.confirmed(this.vacationInfo.postId).then(() => {
      this.$root.$emit('event-show-snackbar-infinity', '確認しました。');
      // 確認者取得
      VacationRequestApi.findConfirmedUser(String(this.vacationInfo.postId.value)).then((res) => {
        this.confirmedUserList = res.data;
      });
    });
  }

  isDisabled():boolean {
    let rtnVal = false;
    this.confirmedUserList.forEach((user) => {
      if (user.userId.value === this.loginUser.userId.value) {
        rtnVal = true;
      }
    });
    return rtnVal;
  }

  rtnComment(event:any):void{
    const commentDetail = CommentDetail.create(event.commentId, this.loginUser, event.commentMessage, this.vacationInfo.postId);

    this.vacationInfo.comment.push(commentDetail);
  }
  deleteComment(commentId:CommentId): void{
    this.vacationInfo.comment = this.vacationInfo.comment.filter((comment) => comment.body.commentId.value !== commentId.value);
  }
}
